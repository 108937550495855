@keyframes placeHolderShimmer {
  0% {
    background: #ececec;
  }

  30% {
    background: #f7f7f7;
  }

  50% {
    background: #ececec;
  }

  80% {
    background: #f7f7f7;
  }

  100% {
    background: #ececec;
  }
}

.loading-animation {
  animation: placeHolderShimmer 3s infinite;
}
