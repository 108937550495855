* {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.chat-title {
  color: #7554a0;
  font-weight: 800;
  font-size: 24px;
}

.chat-subtitle {
  color: #7554a0;
  font-weight: 600;
  font-size: 12px;
  padding-top: 4px;
}

.message-form-container {
  padding: 18px;
}

.message-form {
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #3b2a50;
  background-color: white;
}

.message-input {
  width: 100%;
  background-color: white;
  border: 1px solid white;
  padding: 0px 18px;
  outline: none;
  font-size: 15px;
}

.send-button {
  background-color: white;
  border: 1px solid white;
  padding: 0px 57px;
  cursor: pointer;
  width: 5px;
}

.message-row {
  float: left;
  width: 100%;
  display: flex;
  margin-left: 18px;
}

.message-block {
  width: 100%;
  display: inline-block;
}

.message-avatar {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  color: white;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;
}

.message {
  padding: 5px;
  font-size: 15px;
  border-radius: 6px;
  max-width: 60%;
}

#message-section {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
#message-section::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* bookshelf */

.center-book {
  position: absolute;
  top: 90px;
  left: 230px;
}

.center-add {
  margin-left: 170px;
}

.center-book-right {
  position: absolute;
  top: 90px;
  right: 30px;
}

.bookshelf {
  font-style: normal;
  font-weight: 100;
  font-size: 35px;
  color: #8d5b4c;
}

.add-books {
  font-style: normal;
  font-weight: 100;
  font-size: 35px;
  color: #8d5b4c;
  left: 15px;
  top: 7px;
}

.book-outline {
  color: #d49e38;
  width: 25px;
  height: 25px;
}

.plus-sign1 {
  position: relative;
  left: 4px;
  color: #56ccf2;
}

.back-sign {
  color: #eb5757;
  margin-left: -5px;
}

.forward-sign {
  color: #eb5757;
  margin-left: 5px;
  margin-bottom: 2px;
}

.tick-sign {
  color: #27ae60;
  position: relative;
  left: 5px;
}

.plus-sign2 {
  margin-top: 10px;
  width: 27px;
  height: 30px;
  color: #56ccf2;
}

.search-icon {
  color: #2f333d;
}

.info {
  margin-left: 43px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: #2f333d;
}

.add-info {
  margin-left: 35px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: #2f333d;
}

.btn1 {
  width: 110px;
  height: 33px;
  margin-left: 43px;
  background: #f5f5f5;
  border: 1px solid rgba(40, 48, 68, 0.35);
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.btn2 {
  margin-top: 10px;
  width: 87px;
  height: 33px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
}

.btn3 {
  position: absolute;
  width: 84px;
  height: 33px;
  right: 160px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
}

.text-btn1 {
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  color: #828282;
}

.input-icons {
  position: relative;
}

.input-icons i {
  position: absolute;
  left: 20px;
  top: 3px;
}

.input-field {
  height: 34px;
  background: #ffffff;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 15px;
  color: #806767;
  padding-left: 55px;
}

#mainBackgroundGrey {
  background-color: #ebedee !important;
}

#my-books-options {
  margin: 0px;
  padding: 0px;
  margin-left: 1vw;
}

#my-books-options > div {
  margin: 0px;
  padding: 0px;
}

#my-books-options button {
  margin: 0px;
  padding: 0px;
}

#empty-text {
  line-height: 1em;
  font-size: 1em;
  color: #828282;
  text-align: center;
  height: 60vh;
}

/* Sign Up */

.MainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FeatureCarousel {
  background-image: linear-gradient(#4b76c3, #4b76c3);
  color: white;
}

.Logo {
  width: 200px !important;
  text-align: center !important;
}

.FormContainer {
  max-width: 500px !important;
  margin: auto auto;
}

.SignUpMessage {
  margin: 3em 0 0 4em !important;
  width: max-content;
  letter-spacing: 2px;
}

@media only screen and (max-width: 768px) {
  .SignUpMessage {
    margin: 3em 0 0 0 !important;
    padding: 0;
    text-align: center;
    width: 100%;
  }
}

.TextInput {
  border: none !important;
  background-color: #e0e0e0 !important;
  color: #757575;
  border-radius: 0.75em !important;
}

.SubmitButton {
  background-color: #1a1b41 !important;
  color: #ffffff !important;
  width: 50% !important;
  margin: 20px auto 0 auto !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #757575;
}

.separator:not(:empty)::before {
  margin-right: 0.8em;
}

.separator:not(:empty)::after {
  margin-left: 0.8em;
}

.SocialBrandsContainer {
  display: flex;
  justify-content: space-around;
}

.SocialBrandLogo {
  width: 30px;
  height: 30px;
}

.SignInMessage {
  color: #757575;
  text-align: center;
  font-size: 20px;
}

/* Book Card */

.img-shape {
  width: 80px;
  height: 120px;
  filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.3));
  border-radius: 0px 10px 10px 0px;
}

.table-img-shape {
  width: 65px;
  height: 100px;
  filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.3));
  border-radius: 0px 10px 10px 0px;
}

.img-text {
  min-height: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #4f4f4f;
}

.author-text {
  min-height: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: #6d6d6d;
}

.desc-text {
  height: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}

.author-text-noheight {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: #6d6d6d;
}

.highlight-pic {
  width: 152px;
  height: 228px;
  filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.2));
  border-radius: 2px 10px 10px 2px;
}

.highlight-title {
  font-style: normal;
  font-weight: 100;
  font-size: 30px;
  line-height: 100%;
}

.highlight-button {
  height: 30px;

  background: linear-gradient(90deg, #ffffff 0%, #f4f4f4 100%);
  border: 1px solid rgba(115, 171, 206, 0.4);
  box-sizing: border-box;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

.btn-mybooks {
  width: 150px;
  height: 35px;

  background: linear-gradient(180deg, #537895 -219.09%, #2f333d 245.45%);
  border-radius: 5px;
}

.btn-mybooks-text {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 24px */

  /* Gray 5 */

  color: #e0e0e0;
}

.btn-addbook {
  background: #f2f2f2;
  border: 1px solid #78a1bb;
  box-sizing: border-box;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  height: 25px;
  opacity: 0;
}
.text-addbook {
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  color: #27ae60;
}

.add-btn-pos {
  position: relative;
  left: 20px;
  top: 5px;
}

.cancel-button {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

input[type="search"]:disabled {
  background: #dddddd;
}

.pac-container {
  z-index: 2000 !important;
}
