.main-nav {
  position: absolute;
  width: 100%;
  height: 35px;
  top: 20px;
  left: 0px;
  display: grid;
  grid-template-columns: 2% 1fr 1fr 1fr 3%;
  background: #78a1bb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.user-info-desktop {
  height: 0;
  display: none;
}

.center {
  text-align: center;
  margin-top: 100px;
  font-size: xx-large;
}

.side-rectangle {
  position: absolute;
  width: 190px;
  height: 100vh;
  left: -1px;
  top: 0px;
  background: #2f333d;
}

.logo {
  font-family: "Lato", sans-serif;
  display: grid;
  grid-column: 2/3;
  font-style: normal;
  margin-top: 3px;
  font-weight: normal;
  line-height: 90%;
  color: #f2f2f2;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.41);
}

.menu-link {
  grid-column: 3/4;
}

.menu-link ul {
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-link ul li {
  font-size: 0.9rem;
  list-style: none;
}

.menu-link ul li a {
  color: #f2f2f2;
  transition: 0.5s;
  text-decoration: none;
}
.menu-link ul li:hover > a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  transition: 0.5s;
}

.user-info {
  grid-column: 4/5;
}

.user-info ul {
  height: 35px;
  display: grid;
  grid-template-columns: 65% 1fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-start;
}

.user-info ul li {
  text-align: right;
  list-style: none;
}

.user-info ul li:first-child {
  grid-column: 2/3;
  padding-right: 10px;
}

.user-info .hamburger-menu {
  display: none;
}

.profile-pic {
  width: 30px;
  height: 30px;
}

.handshake-icon {
  color: #f2f2f2;
  width: 21px;
  height: 21px;
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.31));
}

.chat {
  color: #f2f2f2;
  width: 18px;
  height: 18px;
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.31));
}

.bell-icon {
  color: #f2f2f2;
  width: 18px;
  height: 18px;
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.31));
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 35px;
    grid-template-columns: 2% 1fr 1.5fr 1fr 3%;
  }

  .logo,
  .menu-link ul,
  .user-info ul {
    height: 35px;
  }

  .user-info ul {
    grid-template-columns: 40% 5fr repeat(3, 1fr);
  }
}

@media (max-width: 998px) {
  .main-nav {
    height: 35px;
    grid-template-columns: 2% 3fr 3fr 2fr 3%;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .user-info ul {
    height: 35px;
  }

  .mobile-menu-link {
    position: relative;
    z-index: 99;
    background-color: #3d4246;
    height: 200px;
    display: grid;
    grid-column: 4/5;
    align-items: center;
    transition: all 0.5s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 200px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .user-info {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 0.5s linear;
    height: 35px;
    display: flex;
    justify-self: end;
  }

  .user-info .user-info-desktop {
    height: 0;
    display: none;
  }

  .ham-icon {
    color: #2f333d;
    padding-bottom: 13px;
  }

  .user-info .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 35px;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .side-rectangle {
    width: 150px;
  }
}

.navbar {
  /*   z-index should be above .close-navbar-toggler */
  z-index: 2;
}
.close-navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}
.close-navbar-toggler.collapsed {
  z-index: -1;
}
